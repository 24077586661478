import React, { Component } from 'react'
import styled from "styled-components"
import Slider from "react-slick";
import AddressIcon from "../Icons/AddressIcon"
import EmailIcon from "../Icons/EmailIcon"
import CallPhone from "../Icons/CallPhone"


const Wrapper = styled.div`
  position: relative;
  display: none;
  @media(max-width: 1024px) {
    display: block;
  }

  @media (min-width: 600px) {
    .slick-slide {
      padding-right: 1px;
    }
  }
`
const Item = styled.div`
  margin: 40px 0;
  padding: 40px 20px;
  border-radius: 5px;
  border: 1px solid #98DFFF;
  height: 180px;
  display: flex !important;
  align-items: center;
  transition: all 0.3s ease;
  background: white;

  & h4 {
    font-size: 24px;
    line-height: 29px;
    color: #062C44;
    margin-bottom: 5px;
  }
  & p {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    overflow: hidden;
    transition: flex 0.3s ease-out;
  }
`

const Icon = styled.div`
  width: 20% !important;
  display: inline-block;
  vertical-align: top;
  & svg {
    width: 90%;
  }
  @media(max-width: 1100px) {
    width: 15% !important;
  }
`
const TextWrap = styled.div`
  width: 80%;
  margin-left: 5%;
  display: inline-block;
  vertical-align: top;
  & h4 {
    font-size: 24px;
    line-height: 29px;
    color: #062C44;
    margin-bottom: 5px;
  }
  & p {
    text-align: left;
    font-size: 16px;
    line-height: 22px;
    
    a {
      color: #062c44;
    }

    a:hover {
      color: #EA5F2C;
      text-decoration: underline !important;
    }
  }
`
export default class ContactUsCarousel extends Component {
  render() {
    const { data } = this.props
    const settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    };

    return (
      <Wrapper >
        <Slider {...settings}>
          <Item>
            <Icon><AddressIcon /></Icon>
            <TextWrap>
              <h4>Address</h4>
              <p>3752 W US Hwy 90, Lake City, Fl 32055</p>
            </TextWrap>
          </Item>
          <Item>
            <Icon><EmailIcon /></Icon>
            <TextWrap>
              <h4>Email Address</h4>
              <p><a href="mailto:info@probuiltsteel.com">info@probuiltsteel.com</a></p>
            </TextWrap>
          </Item>

          <Item>
            <Icon><CallPhone /></Icon>
            <TextWrap>
              <h4>Contact Number</h4>
              <p><a href="tel:8777541818">1-877-754-1818</a></p>
            </TextWrap>
          </Item>

        </Slider>
      </Wrapper>
    );
  }
}